import gql from "graphql-tag/src";

const UPDATE_ACCOUNT_NAME = gql`
  mutation UpdateAccountName($firstName: String!, $lastName: String!) {
    updateAccountName(firstName:$firstName, lastName:$lastName) {
      ok
      error
      account {
        id
        firstName
        lastName
      }
    }
  }
`;

const UPDATE_ACCOUNT_EMAIL = gql`
  mutation UpdateAccountEmail($email: String!) {
    updateAccountEmail(email:$email) {
      ok
      error
      account {
        id
        email
      }
    }
  }
`;

const UPDATE_ACCOUNT_COMPANY = gql`
  mutation UpdateAccountCompany($company: String!) {
    updateAccountCompany(company:$company) {
      ok
      error
      account {
        id
        company
      }
    }
  }
`;

const UPDATE_ACCOUNT_PASSWORD = gql`
  mutation UpdateAccountPassword($currentPassword: String!, $newPassword: String!) {
    updateAccountPassword(currentPassword:$currentPassword, newPassword:$newPassword) {
      ok
      error
      account {
        id
      }
    }
  }
`;

const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdatePaymentMethod($token: String!) {
    updatePaymentMethod(token: $token) {
      status
      card {
        brand
        brandId
        lastDigits
        expMonth
        expYear
      }
    }
  }
`;

export {
  UPDATE_ACCOUNT_NAME,
  UPDATE_ACCOUNT_EMAIL,
  UPDATE_ACCOUNT_COMPANY,
  UPDATE_ACCOUNT_PASSWORD,
  UPDATE_PAYMENT_METHOD
};
