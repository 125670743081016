<template>
  <div>
    <div class="plans">
      <div v-for="plan in pricingPlans" :key="plan.id" class="plan">
        <div class="plan-details">
          <p class="plan-price">
            <span>{{plan.price | formatCurrency(plan.currency) }}</span>/mo
          </p>

          <h4 class="heading-3">{{plan.name}}</h4>

          <p>
            {{plan.includedTasks | formatNumber}} Tasks /mo<br>
            <span class="fineprint">
              {{plan.additionalMilePrice | formatCurrency(plan.currency)}} per additional 1,000
            </span>
          </p>

          <ul>
            <li v-for="feature in plan.features" :key="feature">
              <CheckmarkIcon/> {{feature}}
            </li>
          </ul>
        </div>

        <template v-if="currentPlan && currentPlan.id === plan.id">
          <button class="button button-full-width" disabled>Current Plan</button>
        </template>
        <template v-else>
          <router-link :to="{name: 'confirm-plan', params: {plan_id: plan.id}}" class="button button-full-width">{{planCTA(plan)}}</router-link>
        </template>
      </div>
    </div>

    <div class="custom-plan">
      <p>
        Custom and Enterprise plans are also available.
        <a href="mailto:enterprise@profanitor.com">Get in touch</a> to learn more.
      </p>
    </div>
  </div>
</template>

<script>
  import CheckmarkIcon from "@/assets/CheckmarkIcon";
  import {GET_PRICING_PLANS} from "../queries.js";

  export default {
    name: "Plans",
    components: {CheckmarkIcon},
    data() {
      return {
        currentPlan: null,
        pricingPlans: []
      }
    },
    apollo: {
      currentPlan: GET_PRICING_PLANS,
      pricingPlans: GET_PRICING_PLANS,
    },
    filters: {
      formatCurrency: function (value, currency) {
        const formatter = Intl.NumberFormat('en-US',  {
          style: 'currency',
          currency: currency,
          minimumFractionDigits: 0
        });

        return formatter.format(value);
      },
      formatNumber: function (value) {
        const formatter = Intl.NumberFormat('en-US');
        return formatter.format(value);
      },
    },
    methods: {
      planCTA(plan) {
        if (this.currentPlan === null) {
          return "Choose Plan";
        }

        return plan.includedTasks > this.currentPlan.includedTasks ? "Upgrade" : "Downgrade";
      }
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../scss/variables";

  .plans {
    display: grid;
    grid-gap: 12px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: 1fr;
    margin: 16px 0;

    h4 {
      margin: 0 0 16px;
    }

    .plan {
      border: 1px solid #ddd;
      padding: 16px;
      border-radius: 8px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &-price span {
        font-size: 32px;
      }

      hr {
        width: 32px;
        height: 1px;
        margin: 0 0 16px;
        background: #999999;
        border: none;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 32px 0;
      }

      ul li {
        display: flex;
        align-items: center;
        margin: 0 0 8px;
      }

      ul li .icon {
        fill: $brand-color;
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
      }
    }
  }

  .custom-plan {
    padding: 16px;
    border-radius: 8px;

    text-align: center;
    color: #666;

    h4 {
      margin: 0 0 16px;
    }
  }
</style>
