<template>
  <AccountSettingsForm v-on="$listeners" cta="Change Password" @submit.prevent="onSubmit" :enable-primary-button="enableSaveButton" :saving="saving">
    <PFormError v-if="errorMessage">{{errorMessage}}</PFormError>

    <PFormField label="Current password">
      <PTextField required id="current-password" type="password" v-model="currentPassword"></PTextField>
    </PFormField>

    <PFormField label="New password" hint="Minimum 8 characters">
      <PTextField required id="new-password" type="password" v-model="newPassword"></PTextField>
    </PFormField>

    <PFormField label="Confirm new password">
      <PTextField required id="new-password-confirmation" type="password" v-model="newPasswordConfirmation"></PTextField>
    </PFormField>

  </AccountSettingsForm>
</template>

<script>
  import AccountSettingsForm from "./AccountSettingsForm";
  import PTextField from "../../../components/PTextField";
  import PFormError from "../../../components/PFormError";
  import {UPDATE_ACCOUNT_PASSWORD} from "@/screens/settings/mutations";
  import PFormField from "@/components/PFormField";

  export default {
    name: "AccountSettingsEditPasswordForm",
    components: {PFormField, PFormError, PTextField, AccountSettingsForm},

    data() {
      return {
        saving: false,
        errorMessage: null,
        currentPassword: '',
        newPassword: '',
        newPasswordConfirmation: ''
      }
    },

    computed: {
      valid() {
        return (
          this.currentPassword.length > 0 &&
          this.newPassword.length >= 8 &&
          this.newPassword === this.newPasswordConfirmation
        );
      },

      enableSaveButton() {
        return this.valid;
      }
    },

    methods: {
      cancel() {
        this.$emit('cancel');
      },

      async onSubmit() {
        this.saving = true;

        const response = await this.$apollo.mutate({
          mutation: UPDATE_ACCOUNT_PASSWORD,
          variables: {
            currentPassword: this.currentPassword,
            newPassword: this.newPassword
          }
        });

        const {updateAccountPassword} = response.data;

        if (updateAccountPassword.ok) {
          this.$emit('updated');
        } else {
          this.errorMessage = updateAccountPassword.error;
        }

        this.saving = false;
      }
    },
  }
</script>
