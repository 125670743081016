<template>
  <div class="payment-method-card-logo">
    <img height="40" :src="logo" alt="Card logo">
  </div>
</template>

<script>
  import visa from "@/assets/card-icons/visa.svg";
  import amex from "@/assets/card-icons/american-express.svg";
  import discover from "@/assets/card-icons/discover.svg";
  import mastercard from "@/assets/card-icons/mastercard.svg";
  import generic from "@/assets/card-icons/generic.svg";

  export default {
    name: 'CreditCardLogo',
    props: {
      brand: {
        type: String,
        required: true
      },
    },
    computed: {
      logo() {
        switch (this.brand) {
          case "visa":
            return visa;
          case "american-express":
            return amex;
          case "discover":
            return discover;
          case "mastercard":
            return mastercard;
          default:
            return generic;
        }
      }
    },
  }
</script>

<style lang="scss">
  .payment-method-card-logo {
    height: 40px;
    display: inline-block;
    line-height: 0;
  }
</style>
