<template>
  <div class="settings">
    <menu class="settings-nav">
      <li><router-link :to="{name: 'account-settings'}" exact>Account</router-link></li>
      <li><router-link :to="{name: 'billing'}" exact>Plans &amp; Billing</router-link></li>
    </menu>
    <div class="settings-pane">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  export default {
    name: "SettingsScreen"
  }
</script>

<style lang="scss">
  @import "@/scss/variables";

  .settings {
    display: flex;
    margin: 48px auto;
    max-width: 1024px;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(#000, 0.20);
    min-height: 500px;
  }

  .settings-nav {
    width: 175px;
    flex-shrink: 0;
    list-style: none;
    margin: 0;
    border-right: 1px solid #dddddd;
    padding: 40px 0;

    a {
      border-left: 4px solid transparent;
      box-sizing: border-box;
      display: block;
      color: #000;
      text-decoration: none;
      padding: 12px 32px;

      &:hover {
        background: rgba(#000, 0.05);
        color: #000;
      }

      &.router-link-active {
        font-weight: 500;
        color: $brand-color;
        background: $brand-color-light90;
        border-left-color: #009868;
      }
    }
  }

  .settings-pane {
    flex-grow: 1;
    padding: 40px 32px;

    h2 {
      border-bottom: 4px solid #009868;
      display: inline-block;
      clear: both;
      margin: 8px 0 24px;
    }
  }
</style>
