<template>
  <AccountSettingsForm v-on="$listeners" @submit.prevent="onSubmit" :enable-primary-button="enableSaveButton" :saving="saving">
    <p class="field">
      <PTextField aria-label="Company" maxlength="32" autofocus v-model="account.company"></PTextField>
    </p>
  </AccountSettingsForm>
</template>

<script>
  import AccountSettingsForm from "./AccountSettingsForm";
  import PTextField from "../../../components/PTextField";
  import {UPDATE_ACCOUNT_COMPANY} from "@/screens/settings/mutations";

  export default {
    name: "AccountSettingsEditCompanyForm",
    components: {PTextField, AccountSettingsForm},
    props: {
      edit: {
        type: Object,
      }
    },

    data() {
      return {
        saving: false,
        account: {
          company: ''
        }
      }
    },
    computed: {
      modified() {
        return this.account.company !== this.edit.company;
      },
      enableSaveButton() {
        return this.modified;
      }
    },
    created() {
      Object.assign(this.account, this.edit);
    },
    methods: {
      cancel() {
        this.$emit('cancel');
      },
      async onSubmit() {
        this.saving = true;

        const response = await this.$apollo.mutate({
          mutation: UPDATE_ACCOUNT_COMPANY,
          variables: {
            company: this.account.company
          }
        });

        const {updateAccountCompany} = response.data;

        if (updateAccountCompany.ok) {
          this.$emit('updated')
        }

        this.saving = false;
      }
    },
  }
</script>
