<template>
  <div>
    <h2 class="heading-3">Account</h2>

    <div class="account-subsection">
      <h3 class="heading-5">Name</h3>

      <PBox>
        <AccountSettingsEditNameForm v-if="editingName" :edit="account" @cancel="closeAll" @updated="onUpdatedUser" />
        <div v-else class="account-subsection-content">
          <p>
            {{account.firstName}} {{account.lastName}}
          </p>

          <p>
            <a role="button" @click.prevent="editName" href="#">Edit</a>
          </p>
        </div>
      </PBox>
    </div>

    <div class="account-subsection">
      <h3 class="heading-5">Company</h3>

      <PBox>
        <AccountSettingsEditCompanyForm v-if="editingCompany" :edit="account" @cancel="closeAll" @updated="onUpdatedUser" />
        <div v-else class="account-subsection-content">
          <p v-if="account.company">{{account.company}}</p>
          <p v-else class="fineprint">N/A</p>

          <p>
            <a role="button" @click.prevent="editCompany" href="#">Edit</a>
          </p>
        </div>
      </PBox>
    </div>

    <div class="account-subsection">
      <h3 class="heading-5">Email</h3>

      <PBox>
        <AccountSettingsEditEmailForm v-if="editingEmail" :edit="account" @cancel="closeAll" @updated="onUpdatedUser" />
        <div v-else class="account-subsection-content">
          <p>{{account.email}}</p>

          <p>
            <a role="button" @click.prevent="editEmail" href="#">Update Email</a>
          </p>
        </div>
      </PBox>
    </div>

    <div class="account-subsection">
      <h5 class="heading-5">Password</h5>

      <PBox>
        <AccountSettingsEditPasswordForm v-if="editingPassword" @cancel="closeAll" @updated="onUpdatedUser" />
        <div v-else class="account-subsection-content">
          <p class="fineprint">
            🔒
          </p>

          <p>
            <a role="button" @click.prevent="editPassword" href="#">Change Password</a>
          </p>
        </div>
      </PBox>
    </div>

  </div>
</template>

<script>
  import AccountSettingsEditNameForm from "./components/AccountSettingsEditNameForm";
  import AccountSettingsEditCompanyForm from "./components/AccountSettingsEditCompanyForm";
  import AccountSettingsEditEmailForm from "./components/AccountSettingsEditEmailForm";
  import AccountSettingsEditPasswordForm from "./components/AccountSettingsEditPasswordForm";
  import {GET_ACCOUNT} from "./queries.js";
  import PBox from "@/components/PBox";

  export default {
    name: "AccountSettingsScreen",
    components: {
      PBox,
      AccountSettingsEditPasswordForm,
      AccountSettingsEditEmailForm,
      AccountSettingsEditNameForm,
      AccountSettingsEditCompanyForm,
    },
    data() {
      return {
        account: {},
        editingName: false,
        editingCompany: false,
        editingEmail: false,
        editingPassword: false
      }
    },
    apollo: {
      account: GET_ACCOUNT
    },
    methods: {
      onUpdatedUser() {
        this.closeAll();
      },
      closeAll() {
        this.editingName = false;
        this.editingCompany = false;
        this.editingEmail = false;
        this.editingPassword = false;
      },
      editName() {
        this.closeAll();
        this.editingName = true;
      },
      editCompany() {
        this.closeAll();
        this.editingCompany = true;
      },
      editEmail() {
        this.closeAll();
        this.editingEmail = true;
      },
      editPassword() {
        this.closeAll();
        this.editingPassword = true;
      }
    },
  }
</script>

<style lang="scss">
  .account-subsection {
    margin: 0 0 32px;

    &-content {
      display: flex;
      width: 100%;
      justify-content: space-between;

      p {
        margin: 0;
      }
    }
  }
</style>
