<template>
  <div class="box">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: "PBox"
  }
</script>

<style lang="scss">
  @import "../scss/variables";

  .box {
    background: #ffffff;
    border: 1px solid $border-color;
    border-radius: 6px;
    padding: 24px;
  }
</style>
