import gql from "graphql-tag";

const GET_PRICING_PLANS = gql`
  query GetPricingPlans {
    currentPlan {
      id
      # For determining upgrade/downgrade.
      includedTasks
    }
    
    pricingPlans {
      id
      name
      currency
      price
      features
      includedTasks
      upsellingPoint
      additionalTaskCost
      additionalMilePrice
    }
  }
`;

const GET_PRICING_PLAN = gql`
  query GetPricingPlan($planId: ID!) {
    pricingPlan(planId: $planId) {
      id
      name
      currency
      price
      includedTasks
      additionalTaskCost
    }
  }
`

export {
  GET_PRICING_PLANS,
  GET_PRICING_PLAN
};
