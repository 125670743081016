<template>
  <div class="payment-method">
    <div class="payment-method-details">
      <CreditCardLogo :brand="paymentMethod.brandId"/>
      <div>
        <p><strong>{{paymentMethod.brand}} ending in {{paymentMethod.lastDigits}}</strong></p>
        <p class="fineprint">Expires {{paymentMethod.expMonth}}/{{paymentMethod.expYear}}</p>
      </div>
    </div>
    <div>
      <button @click="onUpdateClicked" class="button button-secondary is-medium">Update</button>
    </div>
  </div>
</template>

<script>
  import CreditCardLogo from "./CreditCardLogo";

  export default {
    name: 'PaymentMethod',
    components: {CreditCardLogo},
    props: {
      paymentMethod: {}
    },
    methods: {
      onUpdateClicked() {
        this.$emit('update');
      }
    },
  }
</script>

<style lang="scss">
  .payment-method {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-card-logo {
      margin: 0 16px 0 0;
    }

    &-details {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0 0 4px;
      }

      p:last-child {
        margin: 0;
      }
    }
  }
</style>
