<template>
    <div class="current-plan-details">
      <template v-if="usage">
        <template v-if="usage.currentPlan">
          <div class="details">
            <div class="item">
              <span class="item-title">Your current plan</span>
              <span class="item-value">{{usage.currentPlan.name}}</span>
              <span>
                <router-link :to="{'name': 'plans'}">Change Plan</router-link>
              </span>
            </div>
            <div class="item">
              <span class="item-title">Tasks included</span>
              <span class="item-value">{{usage.currentPlan.includedTasks|formatNumber}} tasks/mo</span>
            </div>
            <div class="item">
              <span class="item-title">Additional tasks cost</span>
              <span class="item-value">{{usage.currentPlan.additionalTaskCost|formatCurrency(usage.currentPlan.currency)}}/task</span>
            </div>
          </div>
          <UsageBar
            :used-tasks="usage.usedTasks"
            :included-tasks="usage.currentPlan.includedTasks"
            :upselling-point="usage.currentPlan.upsellingPoint"
          />
        </template>
        <div v-else class="trial-message">
          <p>
            You free trial is ending in
            <strong>{{usage.trial.daysLeft}} {{pluralize(usage.trial.daysLeft, "day", "days")}}</strong>.
            After that, your servers will be suspended.
          </p>

          <p>
            Pick a plan to keep your servers running smoothly.
          </p>

          <p>
            <router-link :to="{name: 'plans'}" class="button is-medium">Select Your Plan</router-link>
          </p>
        </div>
      </template>
      <BillingModuleLoader v-else></BillingModuleLoader>
    </div>
</template>

<script>
  import UsageBar from "./UsageBar";
  import BillingModuleLoader from "../BillingModuleLoader";
  import {GET_USAGE} from "@/screens/settings/components/plan-details/queries";

  export default {
    name: "CurrentPlanDetails",
    components: {BillingModuleLoader, UsageBar},
    data() {
      return {
        usage: null
      }
    },
    apollo: {
      usage: GET_USAGE
    },
    filters: {
      formatCurrency: function (value, currency) {
        const formatter = Intl.NumberFormat('en-US',  {
          style: 'currency',
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 10
        });

        return formatter.format(value);
      },
      formatNumber: function (value) {
        const formatter = Intl.NumberFormat('en-US');
        return formatter.format(value);
      },
    },
    methods: {
      pluralize(count, singular, plural) {
        return (count === 1) ? singular : plural;
      }
    },
  }
</script>

<style lang="scss">
  .current-plan-details {
    .trial-message {
      p {
        margin: 0 0 16px;
        &:last-child {
          margin: 0;
        }
      }
    }

    .details {
      display: grid;
      grid-template-columns: repeat(3, 4fr);
      grid-gap: 8px;
      margin: 0 0 16px;
    }

    .item {;
      &:last-child {
        margin-right: 0;
      }

      span {
        display: block;
        margin: 0 0 4px;
      }

      &-title {
        color: #3D3D3D;
      }

      &-value {
        color: #333333;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
</style>
