<template>
  <form class="account-settings-form" v-on="$listeners">
    <div class="fields">
      <slot></slot>
    </div>

    <p class="actions">
      <PButton class="button-secondary is-medium" @click="cancel">Cancel</PButton>
      <PButton class="button is-medium" :disabled="!enablePrimaryButton" :loading="saving" type="submit">{{cta}}</PButton>
    </p>
  </form>
</template>

<script>
  import PButton from "../../../components/PButton";

  export default {
    name: "AccountSettingsForm",
    components: {PButton},

    inheritAttrs: false,

    props: {
      enablePrimaryButton: {
        type: Boolean,
        default: false
      },

      saving: {
        type: Boolean,
        default: false
      },

      cta: {
        type: String,
        default: 'Save Changes'
      }
    },

    methods: {
      cancel() {
        this.$emit('cancel');
      },
    },
  }
</script>

<style lang="scss">
  .account-settings-form {
    border-radius: 2px;
    max-width: 500px;

    //.fields >* {
    //  margin: 0 0 16px;
    //}

    .actions {
      margin: 24px 0 0;

      .button:not(:last-child) {
        margin: 0 16px 0 0;
      }
    }
  }
</style>
