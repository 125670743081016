<template>
  <div class="confirm-plan">
    <h1 class="heading-3">Subscribe to Plan</h1>

    <div class="confirm-plan-module">
      <h2 class="heading-4">Payment Information</h2>

      <div class="confirm-plan-payment-info">
        <BillingPaymentInformation @updating="onUpdatingChanged" auto-expand></BillingPaymentInformation>
      </div>
    </div>

    <div v-if="pricingPlan" class="confirm-plan-module">
      <h2 class="heading-4">Plan Summary</h2>

      <div class="plan-summary">
        <div class="plan-summary-heading">
          <strong>{{pricingPlan.name}}</strong>
          <strong>{{pricingPlan.price | formatCurrency(pricingPlan.currency)}}/month</strong>
        </div>

        <div class="plan-summary-content">
          Includes {{pricingPlan.includedTasks | formatNumber}} Tasks/month<br>
          Additional tasks to be billed at {{pricingPlan.additionalTaskCost | formatCurrency(pricingPlan.currency)}}
        </div>
      </div>
    </div>

    <p class="confirm-plan-fineprint">
      By clicking the button below I authorize profanitor to charge you by the amount above.
      Your subscription will renew automatically every month until canceled.
    </p>

    <PButton class="button-full-width" :disabled="!isValid || saving" :loading="saving" @click="confirm">Update my Account</PButton>
  </div>
</template>

<script>
  import PButton from "../../components/PButton";
  import BillingPaymentInformation from "../settings/components/BillingPaymentInformation";

  import {GET_ACCOUNT} from "@/queries";
  import {GET_PRICING_PLAN} from "@/screens/plans/queries";
  import {SUBSCRIBE_TO_PLAN} from "@/screens/plans/mutations";
  import {GET_PAYMENT_METHOD} from "@/screens/settings/queries";
  import {GET_USAGE} from "@/screens/settings/components/plan-details/queries";

  export default {
    name: "ConfirmPlan",
    components: {PButton, BillingPaymentInformation},
    data() {
      return {
        updating: false,
        paymentMethod: null,
        pricingPlan: null,
        saving: false,
      }
    },
    apollo: {
      paymentMethod: GET_PAYMENT_METHOD,
      pricingPlan: {
        query: GET_PRICING_PLAN,
        variables() {
          return {
            planId: this.$route.params['plan_id']
          }
        }
      }
    },
    computed: {
      isValid() {
        const paymentMethod = this.paymentMethod;
        const pricingPlan = this.pricingPlan;

        return (
          !this.updating &&
          paymentMethod != null &&
          pricingPlan != null &&
          paymentMethod.status === 'VALID'
        );
      }
    },
    filters: {
      formatCurrency: function (value, currency) {
        const formatter = Intl.NumberFormat('en-US',  {
          style: 'currency',
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 5
        });

        return formatter.format(value);
      },
      formatNumber: function (value) {
        const formatter = Intl.NumberFormat('en-US');
        return formatter.format(value);
      },
    },
    methods: {
      onUpdatingChanged(value) {
        this.updating = value;
      },
      confirm() {
        this.saving = true;

        this.$apollo.mutate({
          mutation: SUBSCRIBE_TO_PLAN,
          variables: {
            planId: this.pricingPlan.id,
          },
          refetchQueries: [
            {
              query: GET_USAGE
            },
            {
              query: GET_ACCOUNT
            }
          ],
          awaitRefetchQueries: true
        }).then(() => {
          this.$router.push({
            name: 'billing'
          })
        }).finally(() => {
          this.saving = false;
        });
      }
    },
  }
</script>

<style lang="scss">
  .confirm-plan {
    max-width: 440px;
    margin: 32px auto;
    padding: 24px;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(#000, 0.20);

    &-module {
      margin: 0 0 24px;
    }

    &-payment-info {
      background: #ffffff;
      border: 1px solid #dddddd;
      border-radius: 8px;
      padding: 16px;
    }

    .plan-summary {
      border: 1px solid #dddddd;
      border-radius: 8px;

      &-heading {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 16px;
        border-bottom: 1px solid #dddddd;
      }

      &-content {
        padding: 16px;
        background: rgba(#000, 0.03);
      }

      p {
        margin: 0 0 8px;
      }
    }

    &-fineprint {
      text-align: center;
      font-size: 14px;
      color: rgba(#000, 0.5);
      margin: 16px 0 24px 0;
    }
  }
</style>
