<template>
  <div class="payment-information">
    <StripeSDK @loaded="onStripeLoad"></StripeSDK>

    <template v-if="paymentMethod && loadedStripeSDK">
      <UpdatePaymentMethodForm v-if="updating" @cancel="cancelUpdating" @updated="onPaymentMethodUpdated"/>
      <template v-else>
        <PaymentMethod v-if="paymentMethod.card" :payment-method="paymentMethod.card" @update="startUpdating"/>
        <div v-else class="payment-information-content">
          <span class="fineprint">No payment method found.</span>
          <p>
            <a role="button" href="#" @click.prevent="startUpdating">Add a payment method</a>
          </p>
        </div>
      </template>
    </template>
    <BillingModuleLoader v-else></BillingModuleLoader>
  </div>
</template>

<script>
  import PaymentMethod from "./PaymentMethod";
  import UpdatePaymentMethodForm from "./UpdatePaymentMethodForm";
  import BillingModuleLoader from "./BillingModuleLoader";
  import {GET_PAYMENT_METHOD} from "@/screens/settings/queries";
  import StripeSDK from "@/screens/settings/components/StripeSDK";

  export default {
    name: "BillingPaymentInformation",
    components: {StripeSDK, BillingModuleLoader, UpdatePaymentMethodForm, PaymentMethod},
    props: {
      autoExpand: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        updating: false,
        paymentMethod: null,
        loadedStripeSDK: false
      }
    },
    apollo: {
      paymentMethod: {
        query: GET_PAYMENT_METHOD,
        result(result, key) {
          if (this.autoExpand && result.data.paymentMethod.status === "NOT_SET") {
            this.startUpdating();
          }
        }
      }
    },
    methods: {
      onStripeLoad() {
        this.loadedStripeSDK = true;
      },
      startUpdating() {
        this.updating = true;
        this.$emit('updating', this.updating);
      },
      cancelUpdating() {
        this.updating = false;
        this.$emit('updating', this.updating);
      },
      onPaymentMethodUpdated(data) {
        this.updating = false;
        this.$emit('updating', this.updating);
      }
    },
  }
</script>

<style lang="scss">
  .payment-information {
    .placeholder {
      text-align: center;
      padding: 13px 0;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0;
      }
    }
  }
</style>
