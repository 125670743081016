<template>
  <div class="billing-module-loader">
    <PLoader/>
  </div>
</template>

<script>
  import PLoader from "../../../components/loader/PLoader";
  export default {
    name: "BillingModuleLoader",
    components: {PLoader}
  }
</script>

<style lang="scss">
  .billing-module-loader  {
    text-align: center;
  }
</style>
