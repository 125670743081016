<template>
  <div class="usage-bar">
    <div class="usage-bar-header">
      <strong>Usage</strong>
      <div class="usage-bar-header-total">
        <span><strong>{{usedTasks | formatNumber}}</strong> tasks completed this month</span>
      </div>
    </div>

    <div class="usage-bar-wrapper">
      <div :style="{width: includedBarWidth}" class="usage-bar-segment"></div>
      <div v-if="additionalUsage > 0" :style="{width: additionalBarWidth}" class="usage-bar-segment"></div>
    </div>

    <div class="usage-bar-footer">
      <div class="usage-bar-footer-item usage-bar-legend">
        <div class="usage-bar-legend-item">
          <span class="swatch swatch-included"></span>
          <span><strong>{{includedUsage | formatNumber}}</strong> included in plan</span>
        </div>
        <div class="usage-bar-legend-item">
          <span class="swatch swatch-additional"></span>
          <span><strong>{{additionalUsage | formatNumber}}</strong>  additional tasks</span>
        </div>
      </div>
      <div v-if="showWarning" class="usage-bar-footer-item  usage-bar-warning">
        <img src="@/assets/icons/warning.svg" alt="!">
        <span>
          Upgrading to the next plan will save you more.
          <router-link :to="{name: 'plans'}">Learn more</router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "UsageBar",
    props: {
      includedTasks: {
        type: Number,
        required: true
      },
      upsellingPoint: {
        type: Number,
        default: null
      },
      usedTasks: {
        type: Number,
        required: true
      },
    },
    computed: {
      includedUsage() {
        return Math.min(this.includedTasks, this.usedTasks);
      },
      additionalUsage() {
        return this.usedTasks - this.includedUsage;
      },
      includedBarWidth() {
        let percentage = (100.0 / Math.max(this.includedTasks, this.usedTasks)) * Math.min(this.includedTasks, this.usedTasks);

        if (percentage > 0.0) {
          percentage = Math.max(0.5, percentage);
        }

        return `${percentage}%`;
      },
      additionalBarWidth() {
        let percentage = (100.0 / Math.max(this.includedTasks, this.usedTasks)) * Math.max(0, this.usedTasks - this.includedTasks);
        percentage = Math.min(99.5, percentage);
        return `${percentage}%`;
      },
      showWarning() {
        return this.upsellingPoint != null && this.usedTasks >= this.upsellingPoint;
      }
    },
    filters: {
      formatNumber: function (value) {
        const formatter = new Intl.NumberFormat('en-US');
        return formatter.format(value);
      }
    }
  }
</script>

<style lang="scss">
  $usage-bar-height: 16px;

  .usage-bar {
    &-wrapper {
      position: relative;
      background: #F2F1F4;
      box-shadow: 0 1px 4px 0 rgba(0,0,0,0.15);
      border-radius: $usage-bar-height / 2;
      overflow: hidden;
      line-height: 0;
      height: $usage-bar-height;
      margin: 8px 0;
    }

    &-segment {
      height: $usage-bar-height;
      display: inline-block;

      &:nth-child(1) { background: #009868; }
      &:nth-child(2) { background: #ffceb3; }

      &:last-child {
        border-top-right-radius: $usage-bar-height / 2;
        border-bottom-right-radius: $usage-bar-height / 2;
      }
    }

    &-legend {
      display: flex;
      flex-wrap: wrap;

      &-item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        flex-shrink: 1;
      }

      .swatch {
        flex-shrink: 0;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        margin-right: 6px;
        &-included { background: #009868; }
        &-additional { background: #ffceb3; }
      }
    }

    &-header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;

      &-total {
        font-size: 12px
      }
    }

    &-footer {
      font-size: 12px;

      &-item:not(:last-of-type) {
        margin: 0 0 8px;
      }
    }

    &-warning {
      color: #FF5C00;
      display: flex;
      align-items: center;

      img {
        margin: 0 4px 0 0;
      }

      a {
        color: #FF5C00;
        font-weight: bold;
      }
    }
  }
</style>
