import gql from "graphql-tag";

export const GET_USAGE = gql`
  query GetUsage {
    usage {
      currentPlan {
        id
        name
        currency
        price
        includedTasks
        upsellingPoint
        additionalTaskCost
        additionalMilePrice
      }

      currentPeriod {
        startDate
        endDate
      }

      usedTasks

      trial {
        endsAt
        daysLeft
      }
    }
  }
`;
