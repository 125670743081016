<template>
  <div class="plans-screen">
    <h1 class="heading-3 centered">Choose Your Plan</h1>
    <Plans></Plans>
  </div>
</template>

<script>
  import Plans from "./components/Plans";
  export default {
    name: "PlansScreen",
    components: {Plans}
  }
</script>

<style lang="scss">
  .plans-screen {
    margin: 32px auto;
    padding: 24px;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(#000, 0.20);
    max-width: 900px;
  }
</style>
