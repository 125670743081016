<template>
  <div>
    <h2 class="heading-3">Plans &amp; Billing</h2>

    <div class="billing-module">
      <h3 class="heading-5">Plan Details</h3>
      <PBox>
        <CurrentPlanDetails/>
      </PBox>
    </div>

    <div class="billing-module">
      <h3 class="heading-5">Payment Information</h3>
      <PBox>
        <BillingPaymentInformation/>
      </PBox>
    </div>

    <div class="billing-module">
      <h3 class="heading-5">Invoices</h3>
      <PBox>
        <Invoices/>
      </PBox>
    </div>
  </div>
</template>

<script>
  const Invoices = () => import("./components/invoices/Invoices");
  import CurrentPlanDetails from "./components/plan-details/CurrentPlanDetails";
  import BillingPaymentInformation from "./components/BillingPaymentInformation";
  import PBox from "@/components/PBox";

  export default {
    name: "PlansAndBillingScreen",
    components: {Invoices, CurrentPlanDetails, BillingPaymentInformation, PBox}
  }
</script>

<style lang="scss">
  @import "@/scss/variables";

  .billing-module {
    margin: 0 0 32px;

    &-wrapper {
      background: #ffffff;
      border: 1px solid $border-color;
      border-radius: 8px;
      padding: 24px;
    }
  }
</style>
