<template>
  <div class="update-payment-method-form">
    <div class="wrapper">
      <PFormField label="Cardholder name">
        <PTextField id="name" v-model="cardholder"></PTextField>
      </PFormField>

      <PFormField label="Card">
        <span id="card-element"></span>
      </PFormField>

      <div class="actions">
        <PButton class="button-secondary is-medium" @click="onCancel">Cancel</PButton>
        <PButton class="button is-medium" type="submit" :disabled="!isValid" @click="onSubmit" :loading="saving">Save Card</PButton>
      </div>
    </div>
  </div>
</template>

<script>
  import PTextField from "../../../components/PTextField";
  import PButton from "../../../components/PButton";
  import {UPDATE_PAYMENT_METHOD} from "@/screens/settings/mutations";
  import PFormField from "@/components/PFormField";

  export default {
    name: 'UpdatePaymentMethodForm',
    components: {PFormField, PButton, PTextField},
    data() {
      return {
        stripe: null,
        elements: null,
        cardholder: '',
        cardElement: null,
        complete: false,
        saving: false,
      }
    },
    computed: {
      isValid() {
        return this.complete && this.cardholder.trim() !== '';
      }
    },
    mounted() {
      // eslint-disable-next-line
      this.stripe = Stripe('pk_test_v1NCznYNlyo48N54HNxS93vi00duDKTiNB');
      this.elements = this.stripe.elements();

      const style = {
        base: {
          color: '#32325d',
          fontFamily: "-apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', sans-serif",
          fontSmoothing: "antialiased",
          fontSize: "18px",
          '::placeholder': {
            color: '#aab7c4'
          }
        },
        invalid: {
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }

      this.cardElement = this.elements.create('card', {style});
      this.cardElement.mount('#card-element');
      this.cardElement.on('change', (e) => this.onCardNumberChange(e));
    },
    methods: {
      onSubmit() {
        if (this.saving) {
          return false;
        }

        this.saving = true;

        this.stripe.createToken(this.cardElement, {
          name: this.cardholder
        }).then((result) => {
          return this.$apollo.mutate({
            mutation: UPDATE_PAYMENT_METHOD,
            variables: {
              token: result.token.id
            },
            refetchQueries: [
              'GetPaymentMethod'
            ]
          });
        }).then(() => {
          this.$emit('updated');
        }).finally(() => {
          this.saving = false;
        });
      },
      onCancel() {
        this.$emit('cancel');
      },
      onCardNumberChange(event) {
        this.complete = event.complete;
      }
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../scss/variables";
  @import "../../../scss/components/form-fields";

  .update-payment-method-form {
    max-width: 500px;

    .field {
      margin: 0 0 16px;

      label {
        color: rgba(#000, 0.6);
        font-size: 14px;
        font-weight: 500;
        margin: 0 0 4px;
        display: inline-block;
      }
    }

    .actions {
      margin: 24px 0 0;

      .button:not(:last-child) {
        margin: 0 16px 0 0;
      }
    }

    .StripeElement {
      @extend .text-field;
      padding: 12px;
      display: block;

      &--focus {
        @extend .text-field:focus;
      }

      &--invalid {
        border-color: #fa755a;
      }

      &--webkit-autofill {
        background-color: #fefde5 !important;
      }
    }
  }
</style>
