<template>
  <AccountSettingsForm v-on="$listeners" @submit.prevent="onSubmit" :enable-primary-button="enableSaveButton" :saving="saving">
    <p class="field">
      <input aria-label="Company" type="email" required class="text-field" v-model="account.email">
    </p>
  </AccountSettingsForm>
</template>

<script>
  import AccountSettingsForm from "./AccountSettingsForm";
  import {UPDATE_ACCOUNT_EMAIL} from "@/screens/settings/mutations";

  const emailRegex = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,})$/

  export default {
    name: "AccountSettingsEditEmailForm",
    components: {AccountSettingsForm},
    props: {
      edit: {
        type: Object,
      }
    },
    data() {
      return {
        saving: false,
        account: {
          email: ''
        }
      }
    },
    computed: {
      modified() {
        return this.account.email !== this.edit.email;
      },
      valid() {
        return emailRegex.test(this.account.email);
      },
      enableSaveButton() {
        return this.valid && this.modified;
      }
    },
    created() {
      Object.assign(this.account, this.edit);
    },
    methods: {
      cancel() {
        this.$emit('cancel');
      },
      async onSubmit() {
        this.saving = true;

        const response = await this.$apollo.mutate({
          mutation: UPDATE_ACCOUNT_EMAIL,
          variables: {
            email: this.account.email
          }
        });

        const {updateAccountEmail} = response.data;

        if (updateAccountEmail.ok) {
          this.$emit('updated');
        }

        this.saving = false;
      }
    },
  }
</script>
