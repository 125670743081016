<script>
  export default {
    name: "StripeSDK",
    render: () => null,
    mounted() {
      if (document.getElementById("stripe-sdk")) {
        this.$emit('loaded');
      } else {
        const element = document.createElement("script");
        element.id = "stripe-sdk";
        element.src = "https://js.stripe.com/v3/";
        element.onload = () => {
          this.$emit('loaded', {initial: true});
        };

        document.head.appendChild(element);
      }
    }
  }
</script>

