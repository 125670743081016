import gql from "graphql-tag";

const GET_ACCOUNT = gql`
  query GetAccount {
    account:me {
      id
      firstName
      lastName
      company
      email
    }
  }
`;

const GET_PAYMENT_METHOD = gql`
  query GetPaymentMethod {
    paymentMethod {
      id
      status
      card {
        brand
        brandId
        lastDigits
        expMonth
        expYear
      }
    }
  }
`;

export {
  GET_ACCOUNT,
  GET_PAYMENT_METHOD
};
