<template>
  <AccountSettingsForm v-on="$listeners" @submit.prevent="onSubmit" :enable-primary-button="enableSaveButton" :saving="saving">
    <PFormField label="First name">
      <PTextField required maxlength="20" id="first-name" v-model="account.firstName"></PTextField>
    </PFormField>

    <PFormField label="Last name">
      <PTextField required maxlength="20" id="last-name" v-model="account.lastName"></PTextField>
    </PFormField>
  </AccountSettingsForm>
</template>

<script>
  import AccountSettingsForm from "./AccountSettingsForm";
  import PTextField from "../../../components/PTextField";
  import {UPDATE_ACCOUNT_NAME} from "@/screens/settings/mutations";
  import PFormField from "@/components/PFormField";

  export default {
    name: "AccountSettingsEditNameForm",
    components: {PFormField, PTextField, AccountSettingsForm},
    props: {
      edit: {
        type: Object,
      }
    },
    data() {
      return {
        saving: false,
        account: {
          firstName: '',
          lastName: '',
        }
      }
    },
    computed: {
      modified() {
        return (
          this.account.firstName !== this.edit.firstName ||
          this.account.lastName !== this.edit.lastName
        );
      },
      valid() {
        return (
          this.account.firstName.trim() !== '' &&
          this.account.lastName !== null &&
          this.account.lastName.trim() !== ''
        );
      },
      enableSaveButton() {
        return this.valid && this.modified;
      }
    },
    created() {
        Object.assign(this.account, this.edit);
    },
    methods: {
      async onSubmit() {
        this.saving = true;

        const response = await this.$apollo.mutate({
          mutation: UPDATE_ACCOUNT_NAME,
          variables: {
            firstName: this.account.firstName,
            lastName: this.account.lastName
          }
        });

        const {updateAccountName} = response.data;

        if (updateAccountName.ok) {
          this.$emit('updated');
        }

        this.saving = false;
      }
    },
  }
</script>
